<template>
  <div class="page-container-renewal">
    <div class="title-box-quotation">
      <div class="d-flex align-items-center">
        <h1 class="text-white">
          <i class="mdi mdi-view-list"></i> Listagem de Cotações
        </h1>
        <div class="col-12 col-sm-6 col-md d-flex justify-content-sm-end flex-wrap">
          <b-dropdown
            variant="outline-white"
            right
            toggle-class="btn btn-outline-white custom-dropdown"
            no-caret
            class="mr-2 mb-2 mb-md-0"
          >
            <template #button-content>
              <span
                ><i v-bind:class="getIcon(current_mode)"></i> Modo
                {{ current_mode }}</span
              >
              <span class="triangle-down ml-1 mb-1"></span>
            </template>
            <b-dropdown-item @click="setViewMode('Listagem')">
              <span class="color-purple"
                ><i class="mdi mdi-view-list mr-1"></i> Modo Listagem</span
              >
            </b-dropdown-item>
            <b-dropdown-item @click="setViewMode('painel')">
              <i class="mdi mdi-file-table-box-multiple-outline mr-2"></i> Modo
              Painel
            </b-dropdown-item>
          </b-dropdown>

          <div class="button-group button-group-renewal">
            <button
              class="btn btn-outline-white"
              v-b-modal.modal-filter
              style="border-radius: 5px 0px 0px 5px"
            >
              <i class="fas fa-filter"></i>
              <span class="d-none d-md-inline-block ml-2">Filtros</span>
            </button>
            <button
              class="btn btn-outline-white"
              @click="cleanFilter()"
              style="border-radius: 0px 5px 5px 0px"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="list-view">
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <div>
          <div class="d-flex justify-content-center mb-2">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div class="d-flex align-items-center">
            <strong>{{ msgLoading }}</strong>
          </div>
        </div>
      </div>
      <div class="gray-table table-responsive" style="margin-bottom: 50px">
        <table class="custom-table custom-table-quotation no-wrap" style="width: 96.4% !important;">
          <thead>
            <tr>
              <th>ID</th>
              <th>TIPO DE SEGURO</th>
              <th style="width:180px;">SEGURADORA</th>
              <th>IS</th>
              <th>TOMADOR</th>
              <th>SEGURADO</th>
              <th class="text-center">STATUS</th>
              <th>CRIADO EM</th>
              <th class="text-center custom-actions-th">AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td data-column-title="ID" class="id-column">{{ item.number }}</td>

              <td data-column-title="Tipo de Seguro">
                <span class="text-truncate" style="max-width: 150px">{{
                  item.type
                }}</span>
              </td>

              <td data-column-title="Seguradora">
                <img
                  v-if="item.insurer_icon"
                  :src="item.insurer_icon"
                  class="insurance-logo"
                />
              </td>
              <td data-column-title="Importancia Segurada">
                <div class="d-flex align-items-center">
                  <span
                    class="text-truncate"
                    style="max-width: 150px"
                    :title="item.value_insured"
                    >{{ item.value_insured }}</span
                  >
                </div>
              </td>
              <td data-column-title="Tomador">
                <div class="d-flex align-items-center">
                  <span
                    class="text-truncate"
                    style="max-width: 150px"
                    :title="item.taker"
                    >{{ item.taker }}</span
                  >
                </div>
              </td>
              <td data-column-title="Segurado">
                <div class="d-flex align-items-center">
                  <span
                    class="text-truncate"
                    style="max-width: 150px"
                    :title="item.insured"
                    >{{ item.insured }}</span
                  >
                </div>
              </td>
              <td data-column-title="Status">
                <div
                  v-if="
                    isMaster() &&
                    (item.status == statusQuotationTypes.DECLINED ||
                      item.status == statusQuotationTypes.ISSUE_FAILURE ||
                      item.status == statusQuotationTypes.DRAFT)
                  "
                >
                  <span
                    @click="showModalExeption(item)"
                    :class="[
                      'btn btn-sm status-badge text-uppercase rounded-pill nowrap d-block',
                      classStatusQuotation(item.status),
                    ]"
                    style="min-width: 117px; margin: 0"
                  >
                    {{
                      getEnumKeyByEnumValue(
                        translateStatusQuotationTypes,
                        item.status
                      )
                    }}
                  </span>
                </div>
                <div
                  v-else-if="
                    item.status == statusQuotationTypes.ISSUED && item.policy_id
                  "
                >
                  <span
                    @click="
                      redirect('details-policy', { policyId: item.policy_id })
                    "
                    :class="[
                      'btn btn-sm status-badge text-uppercase rounded-pill nowrap d-block',
                      classStatusQuotation(item.status),
                    ]"
                    style="min-width: 100px; margin: 0;"
                  >
                    {{
                      getEnumKeyByEnumValue(
                        translateStatusQuotationTypes,
                        item.status
                      )
                    }}
                  </span>
                </div>
                <div v-else>
                  <span
                    :class="[
                      'btn btn-sm status-badge text-uppercase rounded-pill nowrap d-block',
                      classStatusQuotation(item.status),
                    ]"
                    style="min-width: 100px; margin: 0"
                  >
                    {{
                      getEnumKeyByEnumValue(
                        translateStatusQuotationTypes,
                        item.status
                      )
                    }}
                  </span>
                </div>
              </td>
              <td data-column-title="Criado em">{{ item.created_at }}</td>
              <td data-column-title="Ação" class="actions text-right ">
                <button
                  v-if="
                    $can.any([
                      'quotation-store',
                      'quotation-update',
                      'quotation-edit',
                    ])
                  "
                  class="btn btn-action-gray"
                  @click="
                    verifyStatusQuotation(item.status)
                      ? null
                      : editQuotation(item.action, item.status)
                  "
                  v-b-tooltip.hover
                  title="Editar"
                >
                  <i
                    class="fas fa-pen"
                    :class="{
                      'icon-btn': !verifyStatusQuotation(item.status),
                      'icon-disabled': verifyStatusQuotation(item.status),
                    }"
                  ></i>
                </button>
                <button
                  v-if="$can('quotation-show')"
                  class="btn btn-action-gray"
                  @click="
                    redirect('details-quotation', { quotationId: item.action })
                  "
                  v-b-tooltip.hover
                  title="Visualizar"
                >
                  <i class="fas fa-eye"></i>
                </button>
                <b-dropdown
                  variant="ellipsis"
                  size="sm"
                  menu-class="custom-dropdown-menu-items"
                  left
                  no-caret
                >
                  <template #button-content>
                    <i class="fa fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item
                    v-if="
                      item.status == statusQuotationTypes.APPROVED && isMaster()
                    "
                    @click="emitQuotation(item.action)"
                    v-b-tooltip.hover
                    title="Gerar apólice manualmente"
                  >
                    <i class="far fa-handshake"></i
                    ><span class="ml-1 format-font-bold">
                      Gerar apólice manualmente</span
                    >
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="
                      item.status == statusQuotationTypes.APPROVED &&
                      $can('policy-issue')
                    "
                    @click="
                      redirect('final-feedback-quotation', {
                        quotationId: item.action,
                      })
                    "
                    v-b-tooltip.hover
                    title="Emitir"
                  >
                    <i class="fas fa-angle-double-right"></i
                    ><span class="ml-1 format-font-bold"> Emitir</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$can.any(['quotation-store'])"
                    @click="cloneQuotation(item.action)"
                    v-b-tooltip.hover
                    title="Clonar cotação"
                  >
                    <i class="far fa-copy"></i
                    ><span class="ml-1 format-font-bold"> Clonar cotação</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$can('quotation-delete')"
                    @click="
                      actionAvaiable(item.status)
                        ? null
                        : showModalDestroy(item)
                    "
                    v-b-tooltip.hover
                    title="Apagar"
                  >
                    <i
                      class="fas fa-trash"
                      :class="{
                        'icon-btn': !actionAvaiable(item.status),
                        'icon-disabled': actionAvaiable(item.status),
                      }"
                    ></i>
                    <span class="ml-1 format-font-bold"> Apagar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-quotation">
          <b-pagination
            @page-click="pageChanged"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            class="my-3 mb-4"
          ></b-pagination>
        </div>
      </div>

      <!-- Modals section -->
      <b-modal
        id="show-modal"
        ref="showModal"
        size="sm"
        class="mt-4"
        hide-footer
        centered
      >
        <template #modal-title>{{ title }}</template>
        <div class="card card-termo-uso mt-1 mb-5">
          <p class="my-4">{{ deletion_data }}</p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i>
              <span class="d-none d-md-inline-block"> Voltar</span>
            </button>
            <button
              @click="checkPolice ? checkPolicyExist() : destroyQuotation()"
              class="btn btn-primary btn-lg text-uppercase px-4"
              data-toggle="modal"
              data-target="#modal-cnpj"
            >
              Excluir<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>

      <!-- Additional modals for clone, emit, filters, and info -->
      <!-- Modal Clonar Cotação -->
      <b-modal
        id="show-modal-confirm-clone"
        ref="showModalConfirmClone"
        hide-footer
        centered
      >
        <template #modal-title>Confirmação</template>
        <div class="card">
          <p>Deseja clonar essa cotação?</p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal-confirm-clone')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i>
              <span class="d-none d-md-inline-block"> Cancelar</span>
            </button>
            <button
              @click="confirmCloneQuotation()"
              class="btn btn-primary btn-lg text-uppercase px-4"
            >
              Confirmar<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>

      <!-- Modal Confirmar Emissão -->
      <b-modal
        id="show-modal-confirm-emit"
        ref="showModalConfirmEmit"
        hide-footer
        size="confirmation"
        centered
      >
        <template #modal-title>Confirmação</template>
        <div class="card">
          <p>Deseja emitir apólice desta cotação?</p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal-confirm-emit')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i>
              <span class="d-none d-md-inline-block"> Cancelar</span>
            </button>
            <button
              @click="confirmEmitQuotation()"
              class="btn btn-primary btn-lg text-uppercase px-4"
            >
              Confirmar<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>

      <!-- Modal Filtros -->
      <b-modal
        ref="modalFilter"
        id="modal-filter"
        size="lg"
        class="mt-4"
        centered
      >
        <template #modal-title
          >Filtrar Cotações</template
        >
        <form class="mb-2">
          <label>Tomador</label>
          <b-form-input
            class="mb-3"
            v-model="filtersTable.policy_holder"
            size="lg"
            id="name-input"
            required
          ></b-form-input>

          <label>Segurado</label>
          <b-form-input
            v-model="filtersTable.insured"
            class="mb-3"
            size="lg"
            id="name-input"
            required
          ></b-form-input>

          <label for="">Processo</label>
          <b-form-input
            class="mb-3"
            v-model="filtersTable.process"
            size="lg"
            id="name-input"
            required
          ></b-form-input>

          <div id="filtros" class="row">
            <div class="col-12 col-lg-6 mb-3">
              <label>Tipo de seguro</label>
              <div class="input-group">
                <b-form-select v-model="filtersTable.insurance" class="mb-3">
                  <b-form-select-option value=""
                    >Sem Filtro</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(value, index) in list_insurance"
                    :value="value.id"
                    :key="index"
                  >
                    {{ value.name }}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <label>Seguradora</label>
              <div class="input-group">
                <b-form-select v-model="filtersTable.insurer" class="mb-3">
                  <b-form-select-option value=""
                    >Sem Filtro</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(value, index) in list_insurers"
                    :value="index"
                    :key="index"
                  >
                    {{ value }}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </div>
          </div>

          <label>Status</label>
          <b-form-select
            v-model="filtersTable.status"
            :options="options()"
            class="mb-3"
          ></b-form-select>

          <label>Data da Criação</label>
          <div id="filtros" class="row">
            <div class="col-12 col-lg-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">de</span>
                </div>
                <b-form-datepicker
                  id="datepicker-dateformat1"
                  locale="pt-BR"
                  nav-button-variant="white"
                  v-model="filtersTable.start_created"
                  menu-class="w-100"
                  calendar-width="100%"
                  :show-decade-nav="false"
                  :hide-header="true"
                  placeholder="dd/mm/aaaa"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">até</span>
                </div>
                <b-form-datepicker
                  id="datepicker-dateformat2"
                  locale="pt-BR"
                  nav-button-variant="white"
                  v-model="filtersTable.end_created"
                  menu-class="w-100"
                  calendar-width="100%"
                  :show-decade-nav="false"
                  :hide-header="true"
                  placeholder="dd/mm/aaaa"
                ></b-form-datepicker>
              </div>
            </div>
          </div>
        </form>
        <template #modal-footer>
          <b-button @click="filterQuotations()" variant="outline-primary">
            Filtrar <i class="fa fa-search"></i>
          </b-button>
        </template>
      </b-modal>

      <!-- Modal de informações da cotação -->
      <b-modal
        id="show-modal-info-quotation"
        ref="showModalInfoQuotation"
        size="lg"
        class="mt-4"
        hide-footer
        centered
        no-stacking
        @hidden="resetToggleValues"
      >
        <template #modal-title>Informações da cotação</template>
        <div class="modal-informacao-erros">
          <b-card-group
            v-if="exception.length === 0"
            class="d-flex justify-content-center"
          >
            <h6 class="modal-title">Essa cotação não possui retornos.</h6>
          </b-card-group>
          <b-card-group
            deck
            class="mt-2 mb-3 card-informacao-erros"
            v-for="(item, index) in exception"
            :key="item.id"
          >
            <b-card
              border-variant="primary"
              header-bg-variant="primary"
              header-text-variant="white"
            >
              <template #header>
                <div
                  @click="setToggleModalExceptionValue(index)"
                  class="cursor-pointer"
                >
                  {{ setModalExceptionHeader(item.insurer_identifier, index) }}
                  <i
                    :class="[
                      'mdi',
                      toggle_value[index]
                        ? 'mdi-chevron-down'
                        : 'mdi-chevron-up',
                    ]"
                  ></i>
                </div>
              </template>
              <div v-if="toggle_value[index]">
                <div class="col-12 col-lg-12 mb-3">
                  <label class="dbo-ui"><strong>Seguradora</strong></label>
                  <p>
                    {{
                      getEnumKeyByEnumValue(
                        translateInsurersTypes,
                        item.insurer_identifier
                      )
                    }}
                  </p>
                </div>

                <hr />
                <div class="col-12 col-lg-12 mb-3">
                  <label class="dbo-ui"><strong>Endpoint</strong></label>
                  <p>{{ jsonParse(item.endpoint) }}</p>
                </div>

                <hr />
                <div class="col-12 col-lg-12 mb-3">
                  <label class="dbo-ui"><strong>Payload</strong></label>
                  <pre>{{ jsonParse(item.payload) }}</pre>
                </div>

                <hr />
                <div class="col-12 col-lg-12 mb-3">
                  <label class="dbo-ui"><strong>Retorno</strong></label>
                  <pre>{{ jsonParse(item.exception) }}</pre>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </div>
        <hr />
        <div
          v-if="quotation_status == statusQuotationTypes.ISSUE_FAILURE"
          class="col-12 col-lg-12 my-3"
        >
          <button v-b-modal.show-modal-update class="btn btn-primary">
            Retornar cotação a fila
          </button>
        </div>
      </b-modal>

      <!-- Modal para retornar status da cotação -->
      <b-modal
        id="show-modal-update"
        ref="showModalUpdate"
        size="md"
        class="mt-4"
        hide-footer
        centered
      >
        <template #modal-title>Retornar status da cotação</template>
        <div class="row mb-3">
          <div class="col-12 col-lg-12">
            <h6>
              Deseja retornar Cotação para a fila trocando o status dela para
              aprovada?
            </h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="
                $refs.showModalUpdate.hide(),
                  $refs.showModalInfoQuotation.show()
              "
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i>
              <span class="d-none d-md-inline-block">Voltar</span>
            </button>
            <button
              class="btn btn-primary btn-lg text-uppercase px-4"
              @click="quotationIssueResend"
            >
              Confirmar<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>


<script>
import quotationService from "@/services/quotation";
import dateFormatter from "@/mixins/dateFormatter";
import numericFormatterMixin from "@/mixins/numericFormatter";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import translateInsurersTypes from "@/enums/translate/insurersTypes";
import insuranceCategoryTecnicTypes from "@/enums/translate/insuranceCategoryTecnicTypes";
import commomsHelper from "@/mixins/commomsHelper";
import authenticationService from "@/services/authentication";
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import uiHelperMixin from "@/mixins/uiHelper";
import tableHelperMixin from "@/mixins/tableHelper";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import policyService from "@/services/policy";

export default {
  name: "list-quotation",
  mixins: [
    numericFormatterMixin,
    dateFormatter,
    commomsHelper,
    uiHelperMixin,
    tableHelperMixin,
  ],
  data: () => ({
    items: [],
    translateInsuranceTypes: translateInsuranceTypes,
    translateInsurersTypes: translateInsurersTypes,
    insuranceCategoryTecnicTypes: insuranceCategoryTecnicTypes,
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    routeRedirect: "list-quotation",
    title: "Excluir cotação",
    deletion_data: "",
    current_deletion: "",
    loading: false,
    checkPolice: true,
    exception: [],
    statusQuotationTypes: statusQuotationTypes,
    rows: 0,
    idConfirmClone: null,
    msgLoading: "Exclusão em andamento.",
    idConfirmEmit: null,
    nameTable: "quotations",
    quotation_id: null,
    quotation_status: null,
    list_insurers: [],
    list_insurance: [],
    toggle_value: {},
    current_mode: "Listagem",
  }),
  async created() {
    if (this.$route.query.filter) {
      this.fillFilterForm();
      this.getQuotations();

      return;
    }

    this.cleanFilter();
    this.getQuotations();
  },
  computed: {
    allowedJudicialFlow() {
      return this.allowedJudicial();
    },
  },
  methods: {
    isMaster() {
      return authenticationService.isMaster();
    },
    showModalExeption(val) {
      this.loadingQuotations();
      quotationService
        .getFailedQuote(val.action, val.insurer)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.exception = response.data;
          this.$refs.showModalInfoQuotation.show();
          this.quotation_id = val.action;
          this.quotation_status = val.status;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showModalDestroy(val) {
      this.current_deletion = val.action;
      this.deletion_data = "Deseja realmente excluir os dados de " + val.taker;
      this.$refs.showModal.show();
    },
    jsonParse(str) {
      return this.isJsonString(str) ? JSON.parse(str) : str;
    },
    checkPolicyExist() {
      this.loading = true;
      this.msgLoading = "Verificando apólice.";
      this.$refs.showModal.hide();
      quotationService
        .getByUID(this.current_deletion)
        .then((response) => {
          if (response.data.policies.length > 0) {
            this.deletion_data =
              "Existe uma apólice de seguro vinculada a esta cotação, deseja realmente excluí-la?";
            this.checkPolice = false;
            this.$refs.showModal.show();
            return;
          }
          this.destroyQuotation();
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cotações.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verifyStatusQuotation(status) {
      return (
        status == this.statusQuotationTypes.ISSUED ||
        status == this.statusQuotationTypes.APPROVED ||
        status == this.statusQuotationTypes.ISSUE
      );
    },
    actionAvaiable(status) {
      return status == this.statusQuotationTypes.ISSUE;
    },
    destroyQuotation() {
      this.loading = true;
      this.msgLoading = "Exclusão em andamento.";
      this.$refs.showModal.hide();
      quotationService
        .destroy(this.current_deletion)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.getQuotations();
        })
        .catch(() => {
          this.showError("Não foi possível remover a cotação.");
        })
        .finally(() => {
          this.checkPolice = true;
          this.loading = false;
        });
    },
    editQuotation(id, status) {
      if (status == this.statusQuotationTypes.ISSUED) {
        return;
      }
      quotationService
        .getByUID(id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          const quotation = response.data;
          const routeName = this.verifyCurrentRouteName(quotation);
          this.redirect(routeName, { quotationId: quotation.id });
          return;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cotações.");
        });
    },
    cloneQuotation(id) {
      this.idConfirmClone = id;
      this.$refs["showModalConfirmClone"].show();
    },
    confirmCloneQuotation() {
      this.loading = true;
      this.msgLoading = "Cópia de cotação em andamento.";
      this.$refs.showModalConfirmClone.hide();
      quotationService
        .clone(this.idConfirmClone)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.redirect("garantee-data", {
            quotationId: response.data.id,
          });
          this.idConfirmClone = null;
        })
        .catch(() => {
          this.showError("Não foi possível clonar a cotação.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    emitQuotation(id) {
      this.idConfirmEmit = id;
      this.$refs["showModalConfirmEmit"].show();
    },
    confirmEmitQuotation() {
      this.loading = true;
      this.msgLoading = "Emissão da apólice em andamento.";
      this.$refs.showModalConfirmEmit.hide();
      quotationService
        .emitQuotation(this.idConfirmEmit)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.idConfirmEmit = null;
          this.redirect("create-edit-policy", { policyId: response.data.id });
        })
        .catch((error) => {
          this.showError(
            "Não foi possível emitir a apólice. " + error.response.data.error
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verifyCurrentRouteName(quotation) {
      if (!quotation.insurance_type_id || !quotation.insurance_category_id) {
        return "garantee-types-selection";
      }

      if (!quotation.document || !quotation.name || !quotation.guarantee) {
        return "garantee-data";
      }

      if (!quotation.insured || !quotation.insured.address) {
        return "insured-data";
      }

      if (
        !quotation.guarantee.contract_number ||
        !quotation.guarantee.guarantee_object ||
        !quotation.guarantee.contract_value
      ) {
        return "quotation-details";
      }

      if (!quotation.guarantee.insurer_selected) {
        return "quotation-summary";
      }
    },
    getQuotations() {
      this.resetAlert();
      this.loadingQuotations();
      quotationService
        .get(this.getFilterForm())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.rows = response.data.total;
          this.formatList(response.data);
          this.loading = false;
          this.getInsurers();
          this.getInsuranceCategories();
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cotações.");
        })
        .finally(() => {
          this.loading = false;
          this.msgLoading = "Exclusão em andamento.";
        });
    },
    getEnumKeyByEnumValue(myEnum, enumValue) {
      let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
      return keys.length > 0 ? keys[0] : null;
    },
    formatList(data) {
      this.items = data.data.map((element) => ({
        number: element.number,
        type: this.getEnumKeyByEnumValue(
          this.insuranceCategoryTecnicTypes,
          element.insurance_category_id
        ),
        value_insured: element.guarantee
          ? this.allowedJudicialFlow.indexOf(element.insurance_category_id) !=
            -1
            ? this.formatCurrency(element.guarantee.contract_value)
            : this.formatCurrency(element.guarantee.guarantee_value)
          : "R$ 0,00",
        taker: element.name,
        status: element.status,
        insured: element.insured ? element.insured.name : null,
        created_at: this.formatDateTimeLocal(element.created_at),
        action: element.id,
        failed_quote: element.failed_quote,
        insurer: element.guarantee ? element.guarantee.insurer_selected : null,
        policy_id: element.policy_id,
        insurer_icon: element.insurer_icon ?? null,
      }));
    },
    redirect(routeName, params = {}) {
      this.$router.push({ name: routeName, params: params });
    },
    filterQuotations() {
      this.setFilterForm();
      this.getQuotations();
      this.$refs.modalFilter.hide();
    },
    cleanFilter() {
      this.filtersTable = {
        policy_holder: "",
        process: "",
        insured: "",
        status: null,
        start_created: "",
        end_created: "",
        document: "",
        insurer: "",
        insurance: "",
      };
      this.setFilterForm();
      this.getQuotations();
    },
    sortingTableChanged(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
      this.setFilterForm();
      this.getQuotations();
    },
    pageChanged(bvEvent, pageNumber) {
      this.currentPage = pageNumber;
      this.setFilterForm();
      this.getQuotations();
    },
    loadingQuotations() {
      this.msgLoading = "";
      this.loading = true;
    },
    options() {
      let options = [];
      let status = Object.entries(this.translateStatusQuotationTypes);
      status.forEach((element) => {
        options.push({ value: element[1], text: element[0] });
      });
      options.sort(function (a, b) {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
      });
      options.splice(0, 0, {
        value: null,
        text: "Sem Filtro",
        notEnabled: true,
        disabled: true,
      });
      return options;
    },
    quotationIssueResend() {
      this.msgLoading = "";
      this.loading = true;
      quotationService
        .quotationIssueResend(this.quotation_id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível alterar status da cotação.");
        })
        .finally(() => {
          this.$bvModal.hide("show-modal-update");
          this.getQuotations();
          this.loading = false;
          this.msgLoading = "Exclusão em andamento.";
        });
    },
    getInsurers() {
      policyService
        .getInsurers()
        .then((response) => {
          this.list_insurers = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de seguradoras.");
        });
    },
    getInsuranceCategories() {
      policyService
        .getAllInsuranceCategories()
        .then((response) => {
          this.list_insurance = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de modalidades.");
        });
    },
    setToggleModalExceptionValue(index) {
      if (this.toggle_value[index] === undefined) {
        return this.$set(this.toggle_value, index, true);
      }
      return this.$set(this.toggle_value, index, !this.toggle_value[index]);
    },
    setModalExceptionHeader(insurer_identifier, index) {
      var message = `Request n° ${index} `;
      if (insurer_identifier != null) {
        return (
          message +
          " - " +
          this.getEnumKeyByEnumValue(
            this.translateInsurersTypes,
            insurer_identifier
          )
        );
      }
      return message;
    },
    resetToggleValues() {
      this.$set(this, "toggle_value", []);
    },
    getIcon(currentMode) {
      if (currentMode === "Listagem") {
        return "mdi mdi-view-list mr-1";
      }
      if (currentMode === "painel") {
        return "mdi mdi-file-table-box-multiple-outline mr-2";
      }
      return "mdi fa-fw mdi-chart-arc mr-1";
    },
    setViewMode(mode) {
      this.current_mode = mode;
      if (mode == "painel") {
        this.redirect("kanban-quotation");
      }
    },
  },
};
</script>

<style>
</style>
