export default {
  'Executante (Performance Bond)': 1,
  'Adiantamento de Pagamentos': 2,
  'Licitante (Bid Bond)': 3,
  'Demandas Judiciais': 4,
  'Construtor': 11,
  'Prestação de serviços': 12,
  'Fornecimento': 13,
  'Judicial Recursal': 41,
  'Judicial Trabalhista': 42,
  'Parcelamento Fiscal': 43,
  'Judicial Fiscal': 44,
  'Judicial Civel': 45,
  'Judicial Recursal ': 46,
  'Safra': 100,
  'Equipamentos': 101,
  'RC Geral': 110,
  'D&O': 111,
  'E&O': 112,
  'Riscos Ambientais': 113,
  'RC Eventos': 114,
  'RC Prestação de Serviços em locais de Terceiros': 115,
  'Retenção de Pagamento': 123,
  'Aduaneiro': 124,
  'Imobiliária': 125,
  'Completion Bond': 126,
  'Manutenção Corretiva': 127,
  'Outros': 131,
  'Pessoa Física': 132,
  'Pessoa Jurídica': 133,
  'Edificações': 134,
  'Apartamento': 135,
  'Casas': 136,
  'Conjuntos Comerciais': 137,
  'Obra Civil': 138,
  'Pontes Viadutos': 139,
  'Túneis': 140,
  'Pavimentação': 141,
  'Instalações e Montagens de Equipamentos': 142,
  'Reformas': 143,
  'Aeronáutico': 144,
  'Frota': 145,
  'Individual': 146,
  'Seguro Empresarial/ Patrimonial': 147,
  'RD Equipamentos': 148,
  'Construtor Naval': 149,
  'Cibernético': 150,
  'Operador Portuário': 151,
  'Náutico': 152,
  'Individual.': 153,
  'Grupo': 154,
  'Avulso': 155,
  'Mensal': 156,
  'Saúde': 157,
  'Odontológico': 158,
  'Outros.': 159,
  'Residencial': 160,
  'Cibernético.': 161,
  'Aduaneiro..': 162,
  'Aduaneiro.': 163,
  'Garantia de energia': 164,
  'Fiança locatícia': 165
}
