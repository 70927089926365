<template>
  <div class="page-container-renewal">
    <div v-if="alertMessage" class="col-12">
      <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
    </div>
    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center">
      <div class="d-flex justify-content-center mb-2">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
    <div class="page-content-renewal checkbox-batch-container">
      <div class="page-header-billet">
        <div class="page-content-billet">
          <div class="d-flex align-items-center">

            <h1 class="text-white"><i class="mdi mdi-barcode-scan"></i> Boletos</h1>
            <div class="checkbox-batch-toolbar ml-auto mr-2 mt-1">
              <button type="button" class="btn btn-outline-white" data-toggle="modal" @click="openPaymentModal()"><i
                  class="fa fa-check"></i><span class="d-none d-md-inline-block ml-2">Marcar como
                  pago(s)</span></button>
            </div>
            <div class="d-flex gap-2 align-items-center button-group-container">
              <div class="button-group button-group-renewal">
                <button class="btn btn-outline-white mt-1" @click="openFilterModal()"
                  style="border-radius: 5px 0px 0px 5px;">
                  <i class="fas fa-filter"></i> <span class="d-md-inline-block ml-2">Filtros</span>
                </button>
                <button class="btn btn-outline-white mt-1" @click="clearFilters()"
                  style="border-radius: 0px 5px 5px 0px;">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="card-body p-0">
            <DatatableBillets ref="datatableBillets" :filters="filters" @toggle-billet-selection="updateSelectedBillets"
              @open-single-billet-modal="openSingleBilletModal" />
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-confirm-payment" ref="confirmModalPayment" size="lg" hide-footer centered>
      <template #modal-title>
        <h5 class="modal-title">Confirmar pagamento</h5>
      </template>
      <div class="modal-content" style="border: 0px;">
        <div class="modal-body">
          <p>Você confirma o pagamento do(s) seguinte(s) boleto(s)?</p>
          <div class="card">
            <div class="card-body bg-light-gray p-3">
              <div class="table-responsive">
                <table role="table" aria-busy="false" aria-colcount="9" class="table-v3">
                  <thead role="rowgroup">
                    <tr>
                      <th class="nowrap">Nro. Ap.</th>
                      <th class="nowrap">CNPJ TOMADOR</th>
                      <th class="nowrap">VALORES</th>
                      <th class="nowrap text-center">DATAS</th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <tr v-for="(billet, index) in selectedBillets" :key="index" role="row"
                      style="--small-badge-width: 5ch;">
                      <td data-column-title="Nro. da Apólice">{{ billet.number }}</td>
                      <td data-column-title="CNPJ do Tomador">{{ billet.policy_holder_document }}</td>
                      <td data-column-title="Valores">
                        <div class="d-flex flex-column">
                          <span class="nowrap">
                            <span class="badge badge-secondary" data-toggle="tooltip" title=""
                              style="width: var(--small-badge-width);"
                              data-original-title="Importância Segurada">I.S</span>
                            {{ formatCurrency(billet.coverage_value) }}
                          </span>
                          <span class="nowrap">
                            <span class="badge badge-secondary" data-toggle="tooltip" title=""
                              style="width: var(--small-badge-width);" data-original-title="Prêmio">PR</span>
                            {{ formatCurrency(billet.insurance_premium) }}
                          </span>
                        </div>
                      </td>
                      <td data-column-title="Vencimento" style="--small-badge-width: 4ch;">
                        <div class="d-flex flex-column align-items-center">
                          <span class="d-flex align-items-center">
                            <span class="badge badge-secondary" data-toggle="tooltip" title=""
                              style="width: var(--small-badge-width);" data-original-title="Vencimento">V</span>
                            <span class="ml-1"> {{ billet.end_date }} </span>
                          </span>
                          <!-- <span class="d-flex align-items-center mt-1">
                              <span class="badge badge-secondary" data-toggle="tooltip" title=""
                                    style="width: var(--small-badge-width);" data-original-title="Prorrogado para">P</span>
                              <span class="ml-2">{{ billet.extended_date }}</span>
                            </span> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
          <footer class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modal-confirm-payment')">
              <i class="fa fa-times mr-1"></i> Cancelar
            </button>
            <button type="button" class="btn btn-primary" @click="markBilletAsPaid()">
              <i class="fa fa-check mr-1"></i> Confirmar
            </button>
          </footer>
        </div>
      </div>
    </b-modal>

    <b-modal id="filter-modal" ref="filterModal" size="md" hide-footer centered>
      <template #modal-title>Filtros</template>

      <div class="form-group">
        <label for="filterPolicyHolder">Tomador</label>
        <b-form-input
          v-model="filters.policy_holder"
          @input="filterSuggestions('policy_holder', filters.policy_holder)"
          placeholder="Digite o nome do tomador"
        />
        <ul v-if="policyHolderSuggestions.length" class="autocomplete-list">
          <li
            v-for="(name, index) in policyHolderSuggestions"
            :key="index"
            @click="selectSuggestion('policy_holder', name)"
          >
            {{ name }}
          </li>
        </ul>
      </div>

      <div class="form-group">
        <label for="filterInsured">Segurado</label>
        <b-form-input
          v-model="filters.insured"
          @input="filterSuggestions('insured', filters.insured)"
          placeholder="Digite o nome do segurado"
        />
        <ul v-if="insuredSuggestions.length" class="autocomplete-list">
          <li
            v-for="(name, index) in insuredSuggestions"
            :key="index"
            @click="selectSuggestion('insured', name)"
          >
            {{ name }}
          </li>
        </ul>
      </div>

      <div class="form-group">
        <label>Seguradora</label>
        <b-form-select v-model="filters.insurer">
          <b-form-select-option :value="null">Sem Filtro</b-form-select-option>
          <b-form-select-option v-for="(value, index) in listInsurers" :value="index" :key="index">
            {{ value }}
          </b-form-select-option>
        </b-form-select>
      </div>

      <div class="form-group">
        <label for="filterPolicyNumber">Status</label>
        <b-form-select v-model="filters.status_id" class="mb-3">
          <b-form-select-option v-for="(value, index) in options()" :value="value.value" :key="index">
            {{ value.text }}
          </b-form-select-option>

        </b-form-select>
      </div>

      <div class="d-block mt-3">
        <div class="d-flex justify-content-between">
          <b-button variant="outline-secondary" @click="$bvModal.hide('filter-modal')">
            Cancelar
          </b-button>
          <b-button variant="primary" @click="applyFilters()">
            Aplicar
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DatatableBillets from './DatatableBillets.vue';
import policyService from "@/services/policy";
import billetService from "@/services/billet";
import numericFormatterMixin from "@/mixins/numericFormatter";

export default {
  name: "ListBillets",
  mixins: [numericFormatterMixin],
  components: {
    DatatableBillets,
  },
  data() {
    return {
      itemsPolicies: [],
      itemsPolicyRenovation: [],
      selectedBillets: [],
      listInsurers: [],
      loading: false,
      filters: {
        policy_holder: '',
        insured: '',
        status_id: null,
      },
      policy_holder_names: [],
      insured_names: [],
      policyHolderSuggestions: [],
      insuredSuggestions: [],
      isPolicyHolderFocused: false,
      isInsuredFocused: false,
    };
  },
  created() {
    this.getInsurers();
    this.getPolicyHolderAndInsuredName();
  },
    mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    markBilletAsPaid() {
      const selectedBilletIds = this.selectedBillets.map(billet => billet.id);
      if (selectedBilletIds.length === 0) {
        return;
      }
      this.loading = true;

      billetService.markAsPaid({ billets: selectedBilletIds })
        .then(() => {
          this.$refs.datatableBillets.fetchBillets();
          this.selectedBillets = [];
        })
        .catch((e) => {
          console.error(e);
        }).finally(() => {
          this.loading = false;
          this.$bvModal.hide('modal-confirm-payment');
        });
    },
    applyFilters() {
      this.$bvModal.hide('filter-modal');
      this.$refs.datatableBillets.fetchBillets(this.filters);
    },
    updateSelectedBillets(billet) {
      const index = this.selectedBillets.findIndex(b => b.id === billet.id);
      if (index === -1) {
        this.selectedBillets.push(billet);
      } else {
        this.selectedBillets.splice(index, 1);
      }
    },
    openSingleBilletModal(billet) {
      this.selectedBillets = [billet];
      this.$bvModal.show("modal-confirm-payment");
    },
    openPaymentModal() {
      if (this.selectedBillets.length === 0) {
        return;
      }
      this.$bvModal.show("modal-confirm-payment");
    },
    openFilterModal() {
      this.$bvModal.show('filter-modal');
    },
    clearFilters() {
      this.filters.policy_holder = '';
      this.filters.insured = '';
      this.filters.status_id = '';
      this.filters.insurer = '';
      this.$refs.datatableBillets.fetchBillets();
    },
    setLoading(status) {
      this.loading = status;
    },
    getInsurers() {
      policyService
        .getInsurers()
        .then((response) => {
          this.listInsurers = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de seguradoras.");
        });
    },
    options() {
      let options = [];
      let status = Object.entries({
        'Pago': 19,
        'Vencido': 20,
        'Em aberto': 21
      });
      status.forEach((element) => {
        options.push({ value: element[1], text: element[0] });
      });
      options.sort(function (a, b) {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
      });
      options.splice(0, 0, {
        value: null,
        text: "Sem Filtro",
        notEnabled: true,
        disabled: true,
      });
      return options;
    },
    getPolicyHolderAndInsuredName() {
      billetService.getPolicyHolderAndInsuredNames()
      .then((response) => {
        this.policy_holder_names = response.data.policy_holders;
        this.insured_names = response.data.insured_names;
      })
      .catch(() => {
        this.showError("Não foi possível recuperar a lista tomadores e segurados na aba de boleto.");
      });
    },
    handleOutsideClick(event) {
      if (!this.$refs.policyHolderInput?.contains(event.target) && !event.target.closest('.autocomplete-list')) {
        this.policyHolderSuggestions = [];
      }
      if (!this.$refs.insuredInput?.contains(event.target) && !event.target.closest('.autocomplete-list')) {
        this.insuredSuggestions = [];
      }
    },

    filterSuggestions(type, query) {
      if (query.length < 3) {
        if (type === 'policy_holder') {
          this.policyHolderSuggestions = [];
        } else if (type === 'insured') {
          this.insuredSuggestions = [];
        }
        return;
      }

      if (type === 'policy_holder') {
        this.policyHolderSuggestions = this.policy_holder_names.filter(name =>
          name.toLowerCase().includes(query.toLowerCase())
        );
      } else if (type === 'insured') {
        this.insuredSuggestions = this.insured_names.filter(name =>
          name.toLowerCase().includes(query.toLowerCase())
        );
      }
    },
  selectSuggestion(type, name) {
    if (type === 'policy_holder') {
      this.filters.policy_holder = name;
      this.policyHolderSuggestions = [];
    } else if (type === 'insured') {
      this.filters.insured = name;
      this.insuredSuggestions = [];
    }
  },
  }
};
</script>

<style scoped></style>
