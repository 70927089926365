<template>
  <div class="list-view">
    <div class="gray-table" style="width: 96.5% !important;">
      <table class="custom-table">
        <thead>
          <tr>
            <th>NRO. AP.</th>
            <th>TOMADOR</th>
            <th>SEGURADO</th>
            <th>PRÊMIO</th>
            <th>VENCIMENTO</th>
            <th class="text-center">STATUS</th>
            <th class="expires-in">VENCE EM</th>
            <th class="text-center">AÇÃO</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="policy in policies" :key="policy.number">
            <td>{{ policy.number ?? policy.policy_origin.number }}</td>
            <td>{{ truncateText(policy.policy_holder_name ?? '--') }}</td>
            <td>{{ truncateText(policy.insured_name) }}</td>
            <td>{{ formatCurrency(policy.policy_origin ? policy.policy_origin.insurance_premium :
              policy.insurance_premium) }}</td>
            <td>{{ formatDateLocal(policy.validity_expiration ?? policy.policy_origin.validity_expiration) }}</td>
            <td>
              <span :class="`status-pill ${getStatusClass(policy.status ?? policy.status_id)}`"
              :style="{ backgroundColor: `var(--status-color)` }">
                {{ translateStatus(policy.status ?? policy.status_id) }}
              </span>
            </td>
            <td>
              <span :class="getClassTextColor(policy.deadline)" class="pill-label-list">
                <small>{{ policy.deadline > 0 ? `${policy.deadline} DIAS` : `há ${Math.abs(policy.deadline)} DIAS` }}</small>
              </span>
            </td>
            <td class="text-center">
            <div class="d-flex justify-content-center align-items-center gap-2">
              <button v-if="policy.status == 1 || policy.status == 2" @click="showModalCreate(policy)" class="btn-action">
                <i class="fa fa-sync"></i>
              </button>
              <button v-else class="btn-action" style="visibility: hidden;">
                <i class="fa fa-sync"></i>
              </button>
              <button
                @click="$can('policy-show') ? redirect('details-policy', { policyId: (policy.policy_origin ? policy.policy_origin.id : policy.id) }) : null"
                class="btn-action">
                <i class="fas fa-eye"></i>
              </button>
              <b-dropdown variant="ellipsis" size="sm" menu-class="custom-dropdown-menu-items" left no-caret>
                <template #button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item v-if="policy.status != 1 && policy.status != 2"
                  @click="showModalStatus(policy, policy.status)">
                  <i class="fa fa-pencil-alt"></i> Alterar renovação da apólice
                </b-dropdown-item>
                <b-dropdown-item v-if="policy.status != 1 && policy.status != 2" @click="downloadAllFile(policy)">
                  <i class="fa fa-file-alt"></i> Fazer download de todos os documentos
                </b-dropdown-item>
                <b-dropdown-item v-if="(policy.status == 1 || policy.status == 2) && isMaster()" @click="automaticRenewal(policy)">
                  <i class="fa fa-clock font-16 me-1"></i> Renovação automática
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </td>

          </tr>
        </tbody>
      </table>
      <div class="modal">
      <b-modal id="show-modal-create" ref="modalCreate" size="md" class="mt-4" hide-footer centered>
        <template #modal-title>Renovar apólice</template>
        <ManualPolicyRenewal :policy-renew-selected="policyRenewSelected" />
      </b-modal>
    </div>
    <div class="modal">
      <b-modal id="show-modal-automatic-renewal" ref="modalAutomaticRenewal" size="lg" hide-footer>
        <template #modal-title>Renovar apólice</template>
        <div class="row form">
          <div class="col-12">
            <AutomaticPolicyRenewal :policy-id="policyIdSelected" />
          </div>
        </div>
      </b-modal>
    </div>
    <b-modal id="modal-renovation-status" ref="modalStatus" size="lg">
      <template #modal-title>
        <div>
          <div>
            <h5 class="modal-title" id="modal-cotacao-status-label">
              Alterar Renovação
            </h5>
            <p class="text-muted mb-0">Revise e altere o status da renovação</p>
          </div>
        </div>
      </template>
      <ModalStatusRenewal 
        :policy-renew-selected="policyRenewSelected" 
        :editing="editing" 
        :url-upload="urlUpload"
        :files="files" 
        @close-modal-status="closeModalStatus" 
        @disable-status-button="disableStatusButton"
        ref="modalStatusRenewal" />
      <template #modal-footer>
        <button 
          type="button" class="btn btn-primary"
          @click="editing ? changeStatusPolicyRenovation() : $refs.modalStatus.hide();"
          :disabled="disable_status_button">
          <b-spinner v-if="loading" small></b-spinner> {{ editing ? 'Alterar' : 'Fechar' }}
        </button>
      </template>
    </b-modal>
      <div class="pagination-container">
        <b-pagination 
          v-model="currentPage"
          :total-rows="totalPolicies" 
          :per-page="perPage" 
          aria-controls="my-table"
          class="my-3 mb-4 pagination-renewal">
        </b-pagination>

      </div>
    </div>
  </div>
</template>

<script>
import dateFormatter from "@/mixins/dateFormatter";
import numericFormatterMixin from '@/mixins/numericFormatter';
import authenticationService from "@/services/authentication";
import policyService from "@/services/policy";
import policyRenovationService from "@/services/policy-renovation";
import fileDownload from "js-file-download";
import commomsHelper from "@/mixins/commomsHelper";
import ManualPolicyRenewal from '@/components/Policy/Renewal/ManualPolicyRenewal.vue';
import ModalStatusRenewal from '@/components/Policy/Renewal/ModalStatusRenewal.vue';
import AutomaticPolicyRenewal from '@/components/Policy/Renewal/AutomaticPolicyRenewal.vue';


export default {
  name: "ListPolicyRenewal",
  mixins: [numericFormatterMixin, dateFormatter, commomsHelper],
  components: { ManualPolicyRenewal, ModalStatusRenewal, AutomaticPolicyRenewal },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      policies: [],
      totalPolicies: 0,
      policyRenewSelected: {},
      editing: false,
      loading: false,
      files: [],
      urlUpload: '',
      policyIdSelected: null,
      disable_status_button: true,
    };
  },
  created() {
    this.getPoliciesRenovationsList();
  },
  watch: {
    currentPage() {
      this.getPoliciesRenovationsList();
    }
  },
  methods: {
    truncateText(text, maxChar = 28) {
      return text.length > maxChar ? `${text.substring(0, maxChar)}...` : text;
    },
    formatDateLocal(date) {
      return new Date(date).toLocaleDateString('pt-BR');
    },
    translateStatus(status) {
      const statusMap = {
        1: 'Renovação próxima',
        2: 'Aguardando contato',
        15: 'Aguardando doc',
        18: 'Aguardando aprovação',
        16: 'Finalizada',
        9: 'Cancelada'
      };
      return statusMap[status] || 'Desconhecido';
    },
    getStatusClass(status) {
      const statusClassMap = {
        1: 'kanban-list-status-orange',
        2: 'kanban-list-status-orange-66',
        15: 'kanban-list-status-orange-33',
        18: 'kanban-list-status-yellow',
        16: 'kanban-list-status-green',
        9: 'kanban-list-status-red'
      };
      return statusClassMap[status] || 'kanban-list-status-orange';
    },
    showModalCreate(policy) {
      if (policy) {
        this.policyRenewSelected = policy;
        this.$refs.modalCreate.show();
      }
    },
    showModalStatus(policy, column) {
      if(column !== 9) {
        policy.cancellation_reason_id = null;
      }
      policy.status_id = column;
      this.editing = column == 15 || column == 18;
      if (policy) {
        this.urlUpload = `/api/v1/policy-renovations/${policy.id}/files`;
        
        policy.user_id = policy.user.id;
        policy.policy_origin_id = policy.policy_origin.id;
        policy.policy_number = policy.number;
        policy.quotation_id = policy.quotation.id;
        this.policyRenewSelected = policy;
        this.$refs.modalStatus.show();
      }
    },
    getClassTextColor(days) {
      if (days < 7) {
        return 'pill-label-danger';
      } else if (days < 15) {
        return 'pill-label-warning';
      }
      return 'pill-label-success';
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    automaticRenewal(policy) {
      this.policyIdSelected = policy.id;
      this.$refs.modalAutomaticRenewal.show();
    },
    createRenovation(policy) {
      this.$emit('create-renovation', policy)
    },
    isMaster() {
      return authenticationService.isMaster();
    },
    closeModalStatus() {
      this.$refs.modalStatus.hide();
      this.getPoliciesRenovationsList();
    },
    getPoliciesRenovationsList(
      policy_holder = '',
      insured = '',
      policy_number = '',
      is_clear = false
    ) {
      this.$emit('update-loading', true)
      let page = is_clear ? 1 : this.currentPage; 
      policyService.getPoliciesRenovationsList({
        page: page, 
        per_page: this.perPage,
        policy_holder: policy_holder,
        insured: insured,
        policy_number: policy_number
      })
        .then(response => {
          if (!response.data.error) {
            this.policies = response.data.data; 
            this.totalPolicies = response.data.total;
            this.currentPage = page;
          }
        })
        .catch(() => {
          this.$emit("show-error", "Não foi possível recuperar as apólices a vencer.");
        })
        .finally(() => {
          this.$emit('update-loading', false)
        });
    },
    downloadAllFile(policyRenovation) {
      this.loading_download = true;
      policyRenovationService
        .getPolicyRenovationAllFiles(policyRenovation.policy_renovation_id)
        .then((response) => {
          if (response.data.error) {
            this.$emit('show-error', response.data.message);
            return;
          }
          fileDownload(response.data, 'download.zip');
          this.loading_download = false;
        })
        .catch(() => {
          this.$emit('show-error', 'Não foi possível realizar o download deste arquivo.');
        });
    },
    disableStatusButton(value) {
       this.disable_status_button = value;
    },
    changeStatusPolicyRenovation() {
      this.$emit('update-loading', true)
      this.resetAlert();
      policyRenovationService
        .updateStatusPolicyRenovation(this.policyRenewSelected.policy_renovation_id, this.policyRenewSelected)
        .then((response) => {
          if (response.data.error) {
            this.$emit('show-error', response.data.message);
            return;
          }
          this.$refs.modalStatusRenewal.uploadFiles();
        })
        .catch(() => {
          this.$emit('show-error', "Não foi possível mudar o status da renovação.");
        })
        .finally(() => {
          this.$emit('update-loading', false)
          this.getPoliciesRenovationsList();
        });
    },
  }
};
</script>

<style scoped>
</style>
